@import url(https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap);
/* Google Fonts */
.main {
  margin-left: 110px;
}

@media screen and (max-width: 1024px) {
  .main {
    margin-left: 0;
  }
}

/* Variables CSS */
:root {
  /* Colors */
  /*Color Mode HSL (hue, saturation, lightness)*/
  --first-color: hsl(354, 71%, 80%);
  --title-color: hsl(244, 24%, 26%);
  --text-color: hsl(244, 16%, 43%);
  --body-color: hsl(258, 60%, 98%);
  --container-color: #ffff;


  /* ----- FONT & TYPOGRAPHY ------ */
  --body-font: 'Sen', sans-serif;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;


  /* ----- FONT WEIGHT ------ */
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  /* ------  Box Shadow -----*/
  --shadow: 0px 5px 20px 0px rgb(69 67 96 / 10%);

  /* ------- Border Radius ---- */
  --border-radius: 20px;
}

/* ---- Responsive Typography --- */
@media screen and (max-width: 1024px) {
  :root {
    --body-font: 'Sen', sans-serif;
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.93rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
  
}

/* ------- Base ---- */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box

}

html {
  scroll-behavior: smooth;
}

body,
button, 
input,
textarea {
  font-family: 'Sen', sans-serif;
  font-family: var(--body-font);
  font-size: 1rem;
  font-size: var(--normal-font-size);
}

body {
  background-color: hsl(258, 60%, 98%);
  background-color: var(--body-color);
  line-height: 1.7;
  color: hsl(244, 16%, 43%);
  color: var(--text-color);
}

.section_title {
  text-align: center;
}

h1, h2, h3 {
  color: hsl(244, 24%, 26%);
  color: var(--title-color);
  font-weight: 600;
  font-weight: var(--font-semibold);
  line-height: 1.2;
}

u1 {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/*  ------ Reusable CSS Classes ----- */
.container {
  max-width: 1080px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.grid {
  display: grid;
}

.section {
  padding-top: 7rem;
  padding-bottom: 2rem;
}

.section_title {
  font-size: 2.25rem;
  font-size: var(--h1-font-size);
  margin-left: 0.875rem;
  font-weight: 700;
  font-weight: var(--font-bold);
  position: relative;
  margin-bottom: 3.75rem;

}

.section__title::before {
  content: '';
  /*background-color: url(./assets/dots-bg.svg);*/
  height: 2.25rem;
  width: 2.25rem;
  position: absolute;
  left: -0.875rem;
  top: -0.875rem;
}

.btn {
  padding: 0.75rem 2rem;
  line-height: 1;
  border-radius: 1.875rem;
  box-shadow: 0 0 1px rgb(0, 0, 0 / 0%);
  border: 1px solid transparent;
  color: #fff;
  display: inline-block;
  background-color: hsl(354, 71%, 80%);
  background-color: var(--first-color);
  font-weight: 700;
  font-weight: var(--font-bold);
}

@-webkit-keyframes button-push {
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  
}

@keyframes button-push {
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  
}

.btn:hover {
  -webkit-animation: button-push 0.3s linear 1;
          animation: button-push 0.3s linear 1;
}
.main {
  margin-left: 110px;
}

.container {
  padding: 5px;
}


@media (max-width: 768px) {
  .main {
    margin-left: 50px;
  }
}
.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--body-color);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    width: 115px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    width: auto;
    height: auto;
  }

  
  .logo-img {
    width: auto; /* Adjust the max width as needed */
    height: auto;
    height: auto; /* Adjust the max height as needed */
  }

  .nav_list {
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    list-style: none;
    align-items: center;
  }
  
  .nav_link {
    font-size: 1.5rem;
    color: var(--title-color);
    font-weight: var(--font-bold);
    transition: 0.3s;
  }
  
  .nav_link:hover {
    color: #efc3e6;
  }
  
  .nav_footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .aside {
      width: 11%;
      padding: 2.5rem 1rem;
    }
    .logo-container {
      height: 1px;
      width: 30px;
      align-items: center;
      display: flex;
    }
  
    .logo-img {
      max-height: 1px;
      max-width: 1px;
    }
  
    .nav_list {
      grid-row-gap: 0.5rem;
      row-gap: 0.5rem;
    }
  
    .nav_link {
      font-size: 1.2rem;
    }
  
    .nav_footer {
      display: none;
    }
  }

  
.home {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Add overflow property to hide overflowing shapes */
}

.intro {
    max-width: 540px;
    text-align: center;
    z-index: 1;
}

.home_img {
    width: 250px;
    border-radius: 10px;
}

.home_name {
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    margin-bottom: 0.5rem;
}

.home_socials_link {
    color: var(--title-color);
    font-size: 1.3rem;
    transition: .3s;
}

.home_socials_link:hover {
    color: #EFC3E6;
}

.scroll_down {
    position: absolute;
    bottom: 2.5rem;
    left: 0;
    width: 100%;
}

.home_scroll_name {
    font-size: var(--small-font-size);
    color: #EFC3E6;
}

.mouse {
    border: 2px solid #EFC3E6;
    display: block;
    height: 1.6rem;
    width: 1.25rem;
    margin: auto;
    margin-top: .75rem;
    border-radius: 1rem;
    position: relative;
}

@-webkit-keyframes ani-mouse {
    0% {
        top: 29%;
    }
    15% {
        top: 50%;
    }
    50% {
        top: 50%;
    }
    100% {
        top: 29%;
    }    
}

@keyframes ani-mouse {
    0% {
        top: 29%;
    }
    15% {
        top: 50%;
    }
    50% {
        top: 50%;
    }
    100% {
        top: 29%;
    }    
}

.wheel {
    background-color: #EFC3E6;
    border-radius: 100%;
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    top: 0.5rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-animation: ani-mouse 2s linear infinite;
            animation: ani-mouse 2s linear infinite;
}

.shapes {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none; /* Add pointer-events property to prevent shapes from interfering with interactions */
}

.shapes {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.shape{
    position: absolute;
}


.bluecrayon {
    left: 5%;
    bottom: 10%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* Center the shape horizontally */
}

.gcrayon {
    left: 8%;
    top: 30%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.browncrayon {
    left: 30%;
    top: 10%;
}

.pcrayon {
    left: 60%;
    bottom: 10%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.crayon4 {
    left: 90%;
    bottom: 10%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); 
}

.yellowcrayon {
    top: 30%;
    right: 2%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);   
}



/* Media queries for responsive design */
@media (max-width: 768px) {
    .home_name {
        font-size: 2rem; /* Adjust font size for smaller screens */
    }

    .home_socials_link {
        font-size: 1rem; /* Adjust font size for smaller screens */
    }

    .home_scroll_name {
        font-size: 0.8rem; /* Adjust font size for smaller screens */
    }
}

/* Additional media queries for even smaller screens if needed */
@media (max-width: 480px) {
    .home_name {
        font-size: 1.5rem;
    }
    .home_socials_link {
        font-size: 0.8rem; /* Adjust font size for even smaller screens */
    }
    
    .home_scroll_name {
        font-size: 0.6rem; /* Adjust font size for even smaller screens */
    }
}
.shape {
    position: absolute;
}

.crayon {
    left: 100%;
    top: 30%;
}

.planet {
    left: -20%;
    top: 30%;
}

.pcrayon {
    left: 65%;
    top: 15%;
}

.pencil {
    left: 55%;
    top: 10%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.blueplanet {
    left: 10%;
    top: 15%;   
}
.about_img{
    width: auto;
    height:auto;
    border-radius: 10px;
    background-color: var(--container-color);
}

.h2 {
    justify-content: center;
}

.about_container{
    grid-template-columns: 1fr 6fr;
    grid-column-gap: 1.875rem;
    -webkit-column-gap: 1.875rem;
            column-gap: 1.875rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
.about_info {
    padding: 1rem;
}
.about_data{
    padding: 1.5rem;
    background-color: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    grid-column-gap: 1.575rem;
    -webkit-column-gap: 1.575rem;
            column-gap: 1.575rem;
    align-items: flex-start;
    position: relative;
}
.about_data::before{
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid var(--container-color);
    position: absolute;
    left: -0.93rem;
    top: 20%;
}
.about_description{
    margin-bottom: 1rem;
    text-align: justify;
    overflow-wrap: break-word;
}

.about_boxes{
    grid-template-columns:  repeat(4, 1fr);
    grid-column-gap: 1.875rem;
    -webkit-column-gap: 1.875rem;
            column-gap: 1.875rem;
    margin-top: 4.5rem;
}
.about_box{
    display: flex;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
}
.about_icon{
    font-size: var(--h1-font-size);
    color: #dedeea;
}
.about_title{
    font-size: 1.875rem;
}
@media screen and (max-width: 1024px) {
.about_container{
    grid-template-columns: 720px;
    justify-content: center;
    grid-row-gap: 1.875rem;
    row-gap: 1.875rem;
}
.about_data::before{
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    left: 49%;
    top: -20px;
}
.about_img{
    justify-self: center;
}
.about_box{
    flex-direction: column;
    grid-row-gap: .5rem;
    row-gap: .5rem;
    text-align: center;
}
}
@media screen and (max-width: 768px) {
.about_container{
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;   
}
.about_data::before{
    left: 47%;
}
.about_data{
    grid-template-columns: 1fr;
    grid-row-gap: 1.875rem;
    row-gap: 1.875rem;
}
.about_boxes{
    grid-template-columns: repeat(2, 150px);
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
    justify-content: center;
}

  }
@media screen and (max-width: 576px) {
    .about_container{
        grid-template-columns: 300px;
        justify-content: center;
        text-align: center;   
    }
}
@media screen and (max-width: 350px) {
    .about_container{
        grid-template-columns: 1fr;   
        justify-content: center;
    }
    .about_boxes{
        grid-template-columns: 1fr;
    }
}
.services_container{
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.875rem;
    -webkit-column-gap: 1.875rem;
            column-gap: 1.875rem;
}

.services_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.875rem;
    gap: 1.875rem;
    justify-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
}

.services_card{
    text-align: center;
    padding: 1.875rem;
    border-radius: var(--border-radius);
    background-color: rgb(178, 178, 230);  
    transition: all 0.3s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.services_card:hover{
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}

.services_card:nth-child(1) {
    background-color: rgba(234, 169, 173, 0.968);
    box-shadow: 0px 5px 20px 0px rgb(255, 86, 96 / -50%);
}

.services_card:nth-child(2) {
    background-color: rgb(186, 211, 245);
    box-shadow: 0px 5px 20px 0px rgb(249, 215, 76 / -50%);
}

.services_img {
    width: 100%; /* Ensures all images take up the full width of the parent container */
    height: auto; /* Automatically adjusts the height while maintaining the aspect ratio */
    margin-bottom: 1.25rem;
}

.services_title{
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: var(--h3-font-size);
}

.services_description {
    flex-grow: 1;
    padding-bottom: 30px; 
    /* Your existing styles for the description */
  }

.services_card:nth-child(2) .services_title{
    color: var(--title-color);
}

.services_card:nth-child(2) .services_description{
    color: var(--text-color);
}

@media screen and (max-width: 1024px) {
    .services_grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .services_grid{
        grid-template-columns: 1fr;
    }
}

.services_button {
    background: #9d9de1;
    margin-top: 3rem;
    border-radius: 999px;
    box-shadow: #9d9de1 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    word-break: break-word;
    border: 0;
}



.services_button:hover {
    background-color: #8683dd;
}

.services_button_icon {
    margin-right: 0.5rem;
}
.resume_container{
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1.875rem;
    -webkit-column-gap: 1.875rem;
            column-gap: 1.875rem;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: left;
    margin-top: 2rem; /* Add margin-top to create space */
}
.timeline{
    background-color: var(--container-color);
    padding: 1.875rem;
    border-radius: var(--border-radius);
    position: relative;
    box-shadow: var(--shadow);
}
.timeline_item{
    position: relative;
    padding-left: 3.125rem;
    padding-bottom: 3.125rem;
}
.timeline_item:last-child{
    padding-bottom: 0;
}
.timeline_item::before{
    content: " ";
    width: 1px;
    height: 100%;
    background-color: var(--first-color);
    position: absolute;
    left: .25rem;
    top: 0;
}
.timeline .icon-briefcase,
.timeline .icon-graduation{
    position: absolute;
    left: -0.4375rem;
    top: 0;
    font-size: var(--h2-font-size);
    color: var(--first-color);
    background-color: var(--container-color);
    padding: 0.4375rem 0;
}
.timeline_date{
    color: #8b88b1;
    font-size: var(--small-font-size);
}
.timeline_title{
    font-size: var(--h3-font-size);
    margin: 0.5rem 0;
}

@media screen and (max-width: 576px) {
    .about_container{
        grid-template-columns: 320px;
        justify-content: center;
        text-align: center;   
    }
}

@media screen and (max-width: 1024px) {
    .resume_container{
        grid-template-columns: 450px;
        justify-content: center;
        grid-row-gap: 1.875rem;
        row-gap: 1.875rem;
    }
    .timeline_item::before{
        left: 2px;
    }
    @media screen and (max-width: 576px) {
        .resume_container{
            grid-template-columns: 300px;
        }
    }
}
@media screen and (max-width: 350px) {
    .timeline_item{
        padding-left: 1.875rem;
        padding-bottom: 1.875rem;   
    }
    .timeline_item::before{
        left: 0;
    }
    .timeline .icon-briefcase,
    .timeline .icon-graduation{
        left: -9px;
    }
    .timeline_text{
        text-align: justify;
    }
}


.work_filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-column-gap: 1.875rem;
    -webkit-column-gap: 1.875rem;
            column-gap: 1.875rem;
    margin-bottom: 2.5rem;
  }
  
.work_item {
  cursor: pointer;
  font-weight: var(--font-bold);
}


.work_container {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.875rem;
  gap: 1.875rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.work_card {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  height: 300px;
}

.work_card img {
  width: 100%;
  height: 50%;
  object-fit: cover;
}

.work_mask {
  background: #a8b6f0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9; /* Change this line */
}

.work_category {
  color: #fff;
  background-color: var(--first-color);
  border-bottom-left-radius: 0.98rem;
  border-bottom-right-radius: 0.98rem;
  position: absolute;
  top: 0;
  left: 1.5rem;
  font-size: var(--small-font-size);
  display: inline-block;
  padding: 0.19rem 0.625rem;
  transition: 0.3s;
}

.work_title {
  color: #fff;
  font-size: var(--h3-font-size);
  margin: 0 0 0.98rem;
  padding: 0 1.25rem;
  position: absolute;
  top: 3.75rem;
  transition: 0.3s;
  opacity: 1;
}

.work_button-link{
  color: #fff;
  position: absolute;
  bottom: 0.5rem;
  left: 1.5rem;
  font-size: var(--h3-font-size);
  display: block;
  background-color: #638ed2;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  transition: 0.3s;
  opacity: 1;
}

.work_button-link:hover {
  background-color: var(--first-color);
}

.work_card:hover .work_button {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .work_filters {
    justify-content: center;
  }
  .work_container {
    grid-template-columns: repeat(2, 330px);
    justify-content: center;
    grid-row-gap: 1.875rem;
    row-gap: 1.875rem;
  }
}

@media screen and (max-width: 768px) {
  .work_container {
    grid-template-columns: 310px;
  }
  .work_filters {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }
}

@media screen and (max-width: 350px) {
  .work_container {
    grid-template-columns: 1fr;
  }
}

.footer {
    padding: 20px;
    text-align: center;
    padding-top: 300px; 
  }
  
  .footer-content {
    font-size: 14px;
    color: #888;
  }
  
.scroll-button {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: #333;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 999;
  }
  
  .scroll-button i {
    color: #fff;
    font-size: 24px;
  }
  
  .scroll-button.show {
    opacity: 1;
  }
  
