.footer {
    padding: 20px;
    text-align: center;
    padding-top: 300px; 
  }
  
  .footer-content {
    font-size: 14px;
    color: #888;
  }
  