.services_container{
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.875rem;
}

.services_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
    justify-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
}

.services_card{
    text-align: center;
    padding: 1.875rem;
    border-radius: var(--border-radius);
    background-color: rgb(178, 178, 230);  
    transition: all 0.3s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.services_card:hover{
    transform: translateY(-10px);
}

.services_card:nth-child(1) {
    background-color: rgba(234, 169, 173, 0.968);
    box-shadow: 0px 5px 20px 0px rgb(255, 86, 96 / -50%);
}

.services_card:nth-child(2) {
    background-color: rgb(186, 211, 245);
    box-shadow: 0px 5px 20px 0px rgb(249, 215, 76 / -50%);
}

.services_img {
    width: 100%; /* Ensures all images take up the full width of the parent container */
    height: auto; /* Automatically adjusts the height while maintaining the aspect ratio */
    margin-bottom: 1.25rem;
}

.services_title{
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: var(--h3-font-size);
}

.services_description {
    flex-grow: 1;
    padding-bottom: 30px; 
    /* Your existing styles for the description */
  }

.services_card:nth-child(2) .services_title{
    color: var(--title-color);
}

.services_card:nth-child(2) .services_description{
    color: var(--text-color);
}

@media screen and (max-width: 1024px) {
    .services_grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .services_grid{
        grid-template-columns: 1fr;
    }
}

.services_button {
    background: #9d9de1;
    margin-top: 3rem;
    border-radius: 999px;
    box-shadow: #9d9de1 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
}



.services_button:hover {
    background-color: #8683dd;
}

.services_button_icon {
    margin-right: 0.5rem;
}