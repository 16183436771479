.about_img{
    width: auto;
    height:auto;
    border-radius: 10px;
    background-color: var(--container-color);
}

.h2 {
    justify-content: center;
}

.about_container{
    grid-template-columns: 1fr 6fr;
    column-gap: 1.875rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
.about_info {
    padding: 1rem;
}
.about_data{
    padding: 1.5rem;
    background-color: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    column-gap: 1.575rem;
    align-items: flex-start;
    position: relative;
}
.about_data::before{
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid var(--container-color);
    position: absolute;
    left: -0.93rem;
    top: 20%;
}
.about_description{
    margin-bottom: 1rem;
    text-align: justify;
    overflow-wrap: break-word;
}

.about_boxes{
    grid-template-columns:  repeat(4, 1fr);
    column-gap: 1.875rem;
    margin-top: 4.5rem;
}
.about_box{
    display: flex;
    column-gap: 1.5rem;
}
.about_icon{
    font-size: var(--h1-font-size);
    color: #dedeea;
}
.about_title{
    font-size: 1.875rem;
}
@media screen and (max-width: 1024px) {
.about_container{
    grid-template-columns: 720px;
    justify-content: center;
    row-gap: 1.875rem;
}
.about_data::before{
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    left: 49%;
    top: -20px;
}
.about_img{
    justify-self: center;
}
.about_box{
    flex-direction: column;
    row-gap: .5rem;
    text-align: center;
}
}
@media screen and (max-width: 768px) {
.about_container{
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;   
}
.about_data::before{
    left: 47%;
}
.about_data{
    grid-template-columns: 1fr;
    row-gap: 1.875rem;
}
.about_boxes{
    grid-template-columns: repeat(2, 150px);
    row-gap: 1.5rem;
    justify-content: center;
}

  }
@media screen and (max-width: 576px) {
    .about_container{
        grid-template-columns: 300px;
        justify-content: center;
        text-align: center;   
    }
}
@media screen and (max-width: 350px) {
    .about_container{
        grid-template-columns: 1fr;   
        justify-content: center;
    }
    .about_boxes{
        grid-template-columns: 1fr;
    }
}