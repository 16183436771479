.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--body-color);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    width: 115px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    width: auto;
    height: auto;
  }

  
  .logo-img {
    width: auto; /* Adjust the max width as needed */
    height: auto;
    height: auto; /* Adjust the max height as needed */
  }

  .nav_list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    list-style: none;
    align-items: center;
  }
  
  .nav_link {
    font-size: 1.5rem;
    color: var(--title-color);
    font-weight: var(--font-bold);
    transition: 0.3s;
  }
  
  .nav_link:hover {
    color: #efc3e6;
  }
  
  .nav_footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .aside {
      width: 11%;
      padding: 2.5rem 1rem;
    }
    .logo-container {
      height: 1px;
      width: 30px;
      align-items: center;
      display: flex;
    }
  
    .logo-img {
      max-height: 1px;
      max-width: 1px;
    }
  
    .nav_list {
      row-gap: 0.5rem;
    }
  
    .nav_link {
      font-size: 1.2rem;
    }
  
    .nav_footer {
      display: none;
    }
  }

  