.main {
  margin-left: 110px;
}

.container {
  padding: 5px;
}


@media (max-width: 768px) {
  .main {
    margin-left: 50px;
  }
}